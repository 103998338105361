@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,500;0,900;1,900&display=swap');

* {
    font-family: 'Roboto', sans-serif;
    text-decoration-line: none;
    color: rgb(78, 78, 78);
}

.container-log {
    height: 100vh;
    width: 100px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

body {
    background-color: #ededed;
}